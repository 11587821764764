var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "orderInfo",
    },
    [
      _c("h3", [_vm._v("客户成交信息")]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            "label-position": "left",
            rules: _vm.rules,
            model: _vm.formInline,
            "label-width": "140px",
            "validate-on-rule-change": false,
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户名称:", prop: "cname" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.formInline.cname,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "cname", $$v)
                          },
                          expression: "formInline.cname",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "客户创建人:", prop: "create_realname" },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.formInline.create_realname,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "create_realname", $$v)
                          },
                          expression: "formInline.create_realname",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "成交顾问:", prop: "follow_realname" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.formInline.follow_realname,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "follow_realname", $$v)
                          },
                          expression: "formInline.follow_realname",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "数据所属部门:",
                        prop: "data_own_structure_whole_name",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.formInline.data_own_structure_whole_name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formInline,
                              "data_own_structure_whole_name",
                              $$v
                            )
                          },
                          expression:
                            "formInline.data_own_structure_whole_name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "客户创建时间:",
                        prop: "customer_create_time",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.formInline.customer_create_time,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formInline,
                              "customer_create_time",
                              $$v
                            )
                          },
                          expression: "formInline.customer_create_time",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "创建人所属部门:",
                        prop: "create_own_structure_whole_name",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.formInline.create_own_structure_whole_name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formInline,
                              "create_own_structure_whole_name",
                              $$v
                            )
                          },
                          expression:
                            "formInline.create_own_structure_whole_name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "学员来源:", prop: "source_name" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.formInline.source_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "source_name", $$v)
                          },
                          expression: "formInline.source_name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户种类:", prop: "leibie" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择", disabled: "" },
                          model: {
                            value: _vm.formInline.leibie,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "leibie", $$v)
                            },
                            expression: "formInline.leibie",
                          },
                        },
                        _vm._l(_vm.leibieArr, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收款公司:", prop: "receiving_unit" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.formInline.receiving_unit,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "receiving_unit", $$v)
                            },
                            expression: "formInline.receiving_unit",
                          },
                        },
                        _vm._l(_vm.receiving_unitArr, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "汇款人名称:", prop: "remitter" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入汇款人名称",
                          maxlength: "50",
                        },
                        model: {
                          value: _vm.formInline.remitter,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "remitter", $$v)
                          },
                          expression: "formInline.remitter",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "缴费日期:", prop: "pay_time" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          "default-value": new Date(),
                          "value-format": "timestamp",
                          placeholder: "选择日期时间",
                          "picker-options": _vm.pickerOptions,
                        },
                        model: {
                          value: _vm.formInline.pay_time,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "pay_time", $$v)
                          },
                          expression: "formInline.pay_time",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "支付方式:", prop: "pay_type" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.formInline.pay_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "pay_type", $$v)
                            },
                            expression: "formInline.pay_type",
                          },
                        },
                        _vm._l(_vm.payType, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "缴费用途:", prop: "payPurposes" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { multiple: "", placeholder: "请选择" },
                          on: {
                            change: _vm.payPurpose,
                            "remove-tag": _vm.removeTag,
                          },
                          model: {
                            value: _vm.formInline.payPurposes,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "payPurposes", $$v)
                            },
                            expression: "formInline.payPurposes",
                          },
                        },
                        _vm._l(_vm.pay_purposeArr, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  (_vm.id ? _vm.order_type == 1 : _vm.charge_type == 1)
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "累计收款总额:", prop: "cumMoney" } },
                        [
                          _c("el-input", {
                            attrs: { type: "text", disabled: "" },
                            model: {
                              value: _vm.formInline.cumMoney,
                              callback: function ($$v) {
                                _vm.$set(_vm.formInline, "cumMoney", $$v)
                              },
                              expression: "formInline.cumMoney",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  (_vm.id ? _vm.order_type != 1 : _vm.charge_type != 1)
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "收款金额(元):", prop: "money" } },
                        [
                          _c("el-input", {
                            attrs: { type: "text", placeholder: "请输入金额" },
                            on: {
                              input: function ($event) {
                                _vm.formInline.money = _vm.formInline.money
                                  .replace(/[^\d.]/g, "")
                                  .replace(/\.{2,}/g, ".")
                                  .replace(".", "$#$")
                                  .replace(/\./g, "")
                                  .replace("$#$", ".")
                                  .replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3")
                                  .replace(/^\./g, "")
                              },
                            },
                            model: {
                              value: _vm.formInline.money,
                              callback: function ($$v) {
                                _vm.$set(_vm.formInline, "money", $$v)
                              },
                              expression: "formInline.money",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  (
                    _vm.id
                      ? _vm.order_type == 1
                      : _vm.charge_type == 1 && _vm.payShow
                  )
                    ? _c(
                        "el-form-item",
                        {
                          ref: "editForm",
                          staticClass: "jiaofei",
                          attrs: { label: "" },
                        },
                        [
                          _vm.baomingShow
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "报名费:" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "110px" },
                                    attrs: { type: "text" },
                                    on: {
                                      input: function ($event) {
                                        _vm.getNumbm(
                                          _vm.formInline.entryFee,
                                          "entryFee"
                                        ),
                                          _vm.vdMoney(),
                                          _vm.Moneys($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.formInline.entryFee,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formInline,
                                          "entryFee",
                                          $$v
                                        )
                                      },
                                      expression: "formInline.entryFee",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.dingjinShow
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "定金:" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "110px" },
                                    attrs: { type: "text" },
                                    on: {
                                      input: function ($event) {
                                        _vm.getNumbm(
                                          _vm.formInline.deposit,
                                          "deposit"
                                        ),
                                          _vm.vdMoney(),
                                          _vm.Moneys($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.formInline.deposit,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formInline, "deposit", $$v)
                                      },
                                      expression: "formInline.deposit",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.xuefeiShow
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "学费:" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "110px" },
                                    attrs: { type: "text" },
                                    on: {
                                      input: function ($event) {
                                        _vm.getNumbm(
                                          _vm.formInline.tuition,
                                          "tuition"
                                        ),
                                          _vm.vdMoney(),
                                          _vm.Moneys($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.formInline.tuition,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formInline, "tuition", $$v)
                                      },
                                      expression: "formInline.tuition",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.ziliaoShow
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "资料费:" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "110px" },
                                    attrs: { type: "text" },
                                    on: {
                                      input: function ($event) {
                                        _vm.getNumbm(
                                          _vm.formInline.datafee,
                                          "datafee"
                                        ),
                                          _vm.vdMoney(),
                                          _vm.Moneys($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.formInline.datafee,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formInline, "datafee", $$v)
                                      },
                                      expression: "formInline.datafee",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.yuyanShow
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "语言培训费:" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "110px" },
                                    attrs: { type: "text" },
                                    on: {
                                      input: function ($event) {
                                        _vm.getNumbm(
                                          _vm.formInline.trainingFee,
                                          "trainingFee"
                                        ),
                                          _vm.vdMoney(),
                                          _vm.Moneys($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.formInline.trainingFee,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formInline,
                                          "trainingFee",
                                          $$v
                                        )
                                      },
                                      expression: "formInline.trainingFee",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.liuxueShow
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "普通留学申请费:" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "110px" },
                                    attrs: { type: "text" },
                                    on: {
                                      input: function ($event) {
                                        _vm.getNumbm(
                                          _vm.formInline.applicationFee,
                                          "applicationFee"
                                        ),
                                          _vm.vdMoney(),
                                          _vm.Moneys($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.formInline.applicationFee,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formInline,
                                          "applicationFee",
                                          $$v
                                        )
                                      },
                                      expression: "formInline.applicationFee",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  (_vm.id ? _vm.order_type == 1 : _vm.charge_type == 1)
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: "合同总额:", prop: "contract_money" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "请输入合同总额",
                            },
                            on: {
                              input: function ($event) {
                                _vm.getNums(_vm.formInline.contract_money),
                                  _vm.Moneys($event),
                                  _vm.vaildateNumber(
                                    "contract_money",
                                    "合同总额"
                                  )
                              },
                            },
                            model: {
                              value: _vm.formInline.contract_money,
                              callback: function ($$v) {
                                _vm.$set(_vm.formInline, "contract_money", $$v)
                              },
                              expression: "formInline.contract_money",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  (_vm.id ? _vm.order_type == 1 : _vm.charge_type == 1)
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "学费总额:",
                            prop: "total_tuition_fees",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "请输入学费总额",
                            },
                            on: {
                              input: function ($event) {
                                _vm.limitInput($event, "price"),
                                  _vm.Moneys($event),
                                  _vm.vaildateNumber(
                                    "total_tuition_fees",
                                    "学费总额"
                                  )
                              },
                            },
                            model: {
                              value: _vm.formInline.total_tuition_fees,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formInline,
                                  "total_tuition_fees",
                                  $$v
                                )
                              },
                              expression: "formInline.total_tuition_fees",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  (_vm.id ? _vm.order_type == 1 : _vm.charge_type == 1)
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "收款批次:",
                            prop: "periodization_set",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.formInline.periodization_set,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formInline,
                                    "periodization_set",
                                    $$v
                                  )
                                },
                                expression: "formInline.periodization_set",
                              },
                            },
                            _vm._l(
                              _vm.periodization_setArr,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  (_vm.id ? _vm.order_type == 1 : _vm.charge_type == 1)
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "收款分期期数:",
                            prop: "periodization",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.formInline.periodization,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formInline, "periodization", $$v)
                                },
                                expression: "formInline.periodization",
                              },
                            },
                            _vm._l(
                              _vm.periodizationArr,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  (_vm.id ? _vm.order_type == 1 : _vm.charge_type == 1)
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "本次收款金额(元):" } },
                        [
                          _c("el-input", {
                            attrs: { type: "text", disabled: "" },
                            model: {
                              value: _vm.formInline.CurrentAmount,
                              callback: function ($$v) {
                                _vm.$set(_vm.formInline, "CurrentAmount", $$v)
                              },
                              expression: "formInline.CurrentAmount",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  (_vm.id ? _vm.order_type == 1 : _vm.charge_type == 1)
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "应收款总额(元):",
                            prop: "should_money",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              maxlength: _vm.numLen,
                              disabled: "",
                            },
                            model: {
                              value: _vm.formInline.should_money,
                              callback: function ($$v) {
                                _vm.$set(_vm.formInline, "should_money", $$v)
                              },
                              expression: "formInline.should_money",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  (_vm.id ? _vm.order_type == 1 : _vm.charge_type == 1)
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "升学层次:", prop: "study_level" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.formInline.study_level,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formInline, "study_level", $$v)
                                },
                                expression: "formInline.study_level",
                              },
                            },
                            _vm._l(_vm.study_levelArr, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "所选院校:", prop: "colleges_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          on: {
                            focus: _vm.getSchool,
                            change: _vm.collegesChange,
                          },
                          model: {
                            value: _vm.formInline.colleges_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "colleges_id", $$v)
                            },
                            expression: "formInline.colleges_id",
                          },
                        },
                        _vm._l(_vm.schoolLevels, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.title, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "所选专业:", prop: "major_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            disabled: _vm.majorShow,
                          },
                          on: { focus: _vm.getMajor, change: _vm.majorChange },
                          model: {
                            value: _vm.formInline.major_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "major_id", $$v)
                            },
                            expression: "formInline.major_id",
                          },
                        },
                        _vm._l(_vm.study_majorArr, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.title, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  (_vm.id ? _vm.order_type == 1 : _vm.charge_type == 1)
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "开学时间:",
                            prop: "term_begin_time",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              "value-format": "timestamp",
                              placeholder: "选择开学时间",
                            },
                            model: {
                              value: _vm.formInline.term_begin_time,
                              callback: function ($$v) {
                                _vm.$set(_vm.formInline, "term_begin_time", $$v)
                              },
                              expression: "formInline.term_begin_time",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "formes",
                      attrs: { label: "所选班级:", prop: "class_id" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            disabled: _vm.classShow,
                          },
                          on: { focus: _vm.getClass },
                          model: {
                            value: _vm.formInline.class_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "class_id", $$v)
                            },
                            expression: "formInline.class_id",
                          },
                        },
                        _vm._l(_vm.classList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.title, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  (_vm.id ? _vm.order_type == 1 : _vm.charge_type == 1)
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "是否团购:",
                            prop: "is_group_purchase",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.formInline.is_group_purchase,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formInline,
                                    "is_group_purchase",
                                    $$v
                                  )
                                },
                                expression: "formInline.is_group_purchase",
                              },
                            },
                            _vm._l(_vm.group_purchase, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.val, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注:", prop: "note" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "91%" },
                        attrs: { placeholder: "请输入内容", maxlength: "100" },
                        model: {
                          value: _vm.formInline.note,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "note", $$v)
                          },
                          expression: "formInline.note",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "zfpz",
                      attrs: { label: "支付凭证:", prop: "voucher" },
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          attrs: {
                            action: "",
                            "show-file-list": false,
                            "http-request": _vm.uploadFile,
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "iconfont icon-jia1",
                            staticStyle: {
                              color: "#1a95ff",
                              "font-size": "14px !important",
                            },
                          }),
                          _vm._v(" \n                        "),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#1a95ff",
                                "font-size": "14px",
                              },
                            },
                            [_vm._v("添加支付详情截图")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        _vm._l(_vm.fileList, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "imgList" },
                            [
                              _c("img", { attrs: { src: item, alt: "" } }),
                              _vm._v(" "),
                              _c("div", { staticClass: "dialog" }, [
                                _c("i", {
                                  staticClass: "el-icon-delete col-w",
                                  on: {
                                    click: function ($event) {
                                      return _vm.del(index, item)
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass: "el-icon-zoom-in col-w",
                                  on: {
                                    click: function ($event) {
                                      return _vm.open(item)
                                    },
                                  },
                                }),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _vm.showImgViewer
                        ? _c("el-image-viewer", {
                            attrs: {
                              "on-close": _vm.closeImgViewer,
                              "url-list": _vm.imgPreviewList,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "align_center" },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.isDisable },
                  on: {
                    click: function ($event) {
                      return _vm.query("ruleForm")
                    },
                  },
                },
                [_vm._v("确定并提交审核")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }